@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'M PLUS Rounded 1c';
  src: url('/fonts/MPLUSRounded1c-Regular.woff2') format('woff2');
  font-display: swap;
}

img,
svg {
  display: inline-block;
  vertical-align: inherit;
  /* override tailwind defaults of block everything */
}

body {
  background-color: white;
}

/*html 16px sets ref for all em, rem calculations*/

html {
  font-size: 16px;
}

* {
  font-family: 'M PLUS Rounded 1c', ui-sans-serif, system-ui, -apple-system,
    BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans',
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
    'Noto Color Emoji' !important;
  -moz-osx-font-smoothing: grayscale !important;
  -webkit-font-smoothing: antialiased !important;
  text-rendering: optimizeLegibility !important;
}

iframe {
  max-width: 100%; /*for all tinymce embedded iframes, anyways a safe condition*/
}

fieldset {
  padding: 0;
  border: none;
}

p {
  margin: 0;
}

.muiModalZindexClass {
  z-index: 1300;
}

.ylurn-no-scrollbar::-webkit-scrollbar {
  display: none;
}

.ylurn-no-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}
